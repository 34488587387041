import React, {useState} from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/products.css"


import img1 from '../images/product10/DSC_0001.jpeg';
import img2 from '../images/product10/DSC_0002.jpeg';
import img3 from '../images/product10/DSC_0003.jpeg';
import img4 from '../images/product10/DSC_0004.jpeg';
import img5 from '../images/product10/DSC_0005.jpeg';

import raty from '../images/raty-banner.jpg'

import ImageGallery from 'react-image-gallery';
 
const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  }
];

const ProductView10 = () => {

  const [price, setPrice] = useState(8999);

  return(
  <Layout>
    <SEO 
      title="Balia ogrodowa" 
      description = { SEO.description }
      urlName="balia-ogrodowa-z-piecem"
    />
    <div className="banner">
        <div className="bannerPlate">
          <p>Balia ogrodowa</p>
        </div>
    </div>
    <div className="productViewPlate">

      <div className="gallery">
        <ImageGallery slideInterval="2000" items={images} />
      </div>
      <div className="productDescription">
          <div id="prodTop"></div>
          <div id="prodLeft"></div>
          <div id="prodBottom"></div>
          <div id="prodRight"></div>
          <div id="productTitle">Balia ogrodowa z piecem 165cm</div>
          <div id="productPrice">{price}zł
          </div>
          <div className="productOption">
              <div className="productDescriptionText">
                  <h3>Opis</h3>
                  <p>
                    Balia ogrodowa wraz z piecem wykonana z drewna świerkowego lub modrzewiowego o średnicy 165cm z plastikowym wkładem. Balia ogrodowa doskonale sprawdzi się jako przedmiot do zrelaksowania się. Nasz produkt jest w pełni skompletowany. Istnieje możliwość zakupu produktu na raty!<br/>
                  </p>
                  <h3>Dane techniczne:</h3>
                  <ul>
                    <li>Plastikowy wkład w balii</li>
                    <li>Piec wewnętrzny - wsad od zewnątrz</li>
                    <li>Schody</li>
                    <li>Pokrywa balii</li>
                    <li>Ławki</li>
                    <li>Ścianka wewnętrzna oddzielająca miejsca siedzące od pieca</li>
                    <li>Średnica zewnętrzna: 165cm</li>
                    <li>Średnica wewnętrzna: 140cm</li>
                    <li>Wysokość: 100cm</li>
                    <li>Grubość ścian: 45 mm</li>
                  </ul>
                  <a href="https://epos.inbank.pl/pl/drewhaus">
                  <img src={raty} className="creditBanner" alt="domek-ogrodowy-raty"/>
                  </a>
                  <p><b>Balie ogrodowe możesz obejrzeć w Gnieźnie! Zajrzyj do zakładki&nbsp; 
                    <Link to="/contact" style={{ color: '#765F52' }} >
                      kontakt
                    </Link> i sprawdź gdzie nas znajdziesz!</b></p>

              </div>
          </div>
      </div>
    </div>
  </Layout>
  )}

export default ProductView10


